<template>
    <div v-if="isDataSourceCorrect" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu"
            v-on:toolbar-button-click="onToolbarClick"
        />

        <DocumentPanel
            :document-info="{ ...this.dataSource.Data.Object.Resolution, __type: 'Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects' }"
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="item.FormId"
            >
                <template v-if="item.FormId == resolution.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>
                            <!--#region Реквизиты документа-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card-panel title="Реквизиты_документа">
                                    <v-field label="Текст_резолюции">
                                        <template v-if="isViewMode" v-slot:view>{{ task.ResolutionText }}</template>
                                        <template v-else v-slot:edit>
                                            <div class="icon-click-row-group">
                                                <div @click="onSelectResolutionText">
                                                    <v-icon small v-tooltip.left-center="selectResolutionTextTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="autocomplete">
                                                    <v-textarea
                                                        :value="task.ResolutionText"
                                                        @input="update({ property: 'Data.Object.Resolution.Tasks[0].ResolutionText', value: $event })"
                                                        rows="3"
                                                        no-resize
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        :rules="requiredRule"
                                                        @focus="focus" 
                                                        @keydown.46="focus" 
                                                        @keydown.13="chooseItem" 
                                                        @keydown.tab="chooseItem" 
                                                        @keydown.40="moveDown" 
                                                        @keydown.38="moveUp"
                                                        @click="clickTA(), getCaretPosPX(id)" 
                                                        :id="id"
                                                    >
                                                    </v-textarea>
                                                    <ul :class="{
                                                        'autocomplete-list': true,
                                                        [id+'-list']: true
                                                    }" v-show="searchMatch.length > 0 && clickedChooseItem">
                                                        <li 
                                                            v-for="(result, index) in searchMatch" 
                                                            :key="index"
                                                            :class="{active: selectedIndex === index}" 
                                                            @click="selectItem(index)" 
                                                            v-html="highlightWord(result)"
                                                        >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </template>
                                    </v-field>
                                    <v-field label="Тип_контроля">
                                        <template v-if="isViewMode || (document.Card.Periodicity > 0 && isMain)" v-slot:view>{{ $t(task.ControlType.Value) }}</template>
                                        <template v-else v-slot:edit>
                                                <v-autocomplete
                                                    :value="task.ControlType"
                                                    @input="update({ property: 'Data.Object.Resolution.Tasks[0].ControlType', value: $event })"
                                                    :items="allowedControlTypes" 
                                                    :item-text="item => $refLocale(item, $i18n.locale)" 
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                        </template>
                                    </v-field>
                                    <v-field v-if="isControlTypeControl" label="Личный_контроль">
                                        <template v-if="isViewMode" v-slot:view>{{ (task.IsSignerControl ? $t("Да") : $t("Нет")) }}</template>
                                        <template v-else v-slot:edit>
                                            <v-switch
                                                :input-value="task.IsSignerControl"
                                                @change="update({ property: 'Data.Object.Resolution.Tasks[0].IsSignerControl', value: $event })"
                                                class="cust-switch"
                                                inset
                                                hide-details
                                            >
                                            </v-switch>
                                        </template>
                                    </v-field>
                                    <v-field v-if="isControlTypeControl" label="Контрольный_срок">
                                        <template v-if="isViewMode || task.ControlType.id == 101" v-slot:view>{{ (task.is_new_record ? formatDate(task.MaxControlDate) : formatDate(task.InnerLimit)) }}</template>
                                        <template v-else v-slot:edit>
                                            <v-menu 
                                                v-model="controleDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="innerLimit | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                        :rules="[ innerLimitRule ]"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="innerLimit"
                                                    @input="controleDateMenu = false"
                                                    color="teal"
                                                    :max="controlDateRange.Max"
                                                    :min="controlDateRange.Min"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>

                                        </template>
                                    </v-field>
                                    <v-field v-if="isControlTypeControl" label="Сроки_продлений">
                                        <template v-slot:view>
                                            {{ (!task.ProlongationDateList ? $t("Не_указано") : task.ProlongationDateList.replaceAll(" 0:00:00", "")) }}
                                        </template>
                                    </v-field>
                                    <v-field label="Статус">
                                        <template v-slot:view>
                                            <div class="onlyReadData more-per-lab-wrap">                                                
                                                <StatusChip :status="GetAvaibleStatuses[$helpers.getTaskStatus(task.WorkStatus)]" />
                                                <StatusChip v-if="resolution.ChanSendStatus" :status="GetAvaibleStatuses[$options.rssStatusKeys[resolution.ChanSendStatus]]" />
                                            </div>
                                        </template>
                                    </v-field>
                                    <v-field label="Способ_отправки" v-if="isCommonServiceEnabled && (hasOuterExecuters || hasOuterEnterprise)">
                                        <template
                                            v-if="isViewMode || !canEditResolutionSendTypeField"
                                            v-slot:view
                                        >
                                                {{ $t(resolutionSendTypes.find(x => x.id === resolution.IsCommon).Value) }}
                                        </template>
                                        <template
                                            v-else
                                            v-slot:edit
                                        >
                                            <v-autocomplete
                                                    :value="resolution.IsCommon"
                                                    @input="update({ property: 'Data.Object.Resolution.IsCommon', value: $event })"
                                                    :items="resolutionSendTypes"
                                                    :item-text="item => $refLocale(item, $i18n.locale)" 
                                                    item-value="id"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                        </template>
                                    </v-field>
                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->

                            <!--#region Краткое содержание документа-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card-panel title="Краткое_содержание_документа">
                                    <v-field label="Документ">
                                        <template v-slot:view>
                                            <v-document-link
                                                :isViewMode="!isEditMode"
                                                :id="dataSource.Data.Object.Resolution.DocumentID" 
                                                :type="'Chancellery|Documents.Document'"
                                                :text="dataSource.Data.Object.Resolution.DocumentNumber"
                                                :common="isDataSourceCommon"
                                            />
                                        </template>
                                    </v-field>
                                    <v-field label="Краткое_содержание_документа">
                                        <template v-slot:view>
                                            {{ (isEuolResolution ? document.Card.Annotation : document.Theme)}}
                                        </template>
                                    </v-field>                                    
                                </v-card-panel>

                                <v-card-panel title="Кем_создано" v-if="isViewMode || isProtocolResolution">
                                    <v-field label="Автор">
                                        <template v-slot:view>
                                            <v-employee-chip
                                                :id="dataSource.Data.Object.Resolution.SignerID"
                                                :name="dataSource.Data.Object.Resolution.SignerName"
                                                :is-employee="true"
                                            >
                                            </v-employee-chip>
                                        </template>
                                    </v-field>
                                    <v-field label="Дата_создания">
                                        <template v-slot:view>
                                            {{ dataSource.Data.Object.Resolution.ResolutionDate ? formatDate(dataSource.Data.Object.Resolution.ResolutionDate) : $t('Не_указано') }}
                                        </template>
                                    </v-field>                                    
                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->

                            <!--#region Исполнители-->
                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <v-card-panel title="Исполнители">
                                    
                                    <template v-slot:titleappend>
                                        <span
                                            class="fbt-after-edit"
                                            v-if="!isViewMode || isExecutersEditMode"
                                            @click="onExecuterSelect"
                                        >
                                            <v-icon 
                                                small 
                                                v-tooltip.left-center="selectExecuterTooltip"
                                            >
                                                fas fa-edit
                                            </v-icon>
                                        </span>
                                    </template>

                                    <template v-if="isViewMode && !isExecutersEditMode">

                                        <v-field
                                            v-if="!!svodExecuters && svodExecuters.length"
                                            label="Ответственный"
                                            :wide="true"
                                        >

                                            <template v-slot:view>
                                                <template v-for="executer in svodExecuters">
                                                    <v-enterprise-chip
                                                        v-if="executer.ExecuterID == guidEmpty"
                                                        :key="executer.id"
                                                        :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                        :main="true"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.id"
                                                        :id="executer.ExecuterID"
                                                        :name="executer.ExecuterName"
                                                        :main="true"
                                                    />
                                                </template>
                                            </template>

                                        </v-field>

                                        <v-field
                                            v-if="executers.length > 0"
                                            :label="(isControlTypeControl ? 'Соисполнители' : 'Исполнители' )"
                                            :wide="true"
                                            :multi="true"
                                        >

                                            <template v-slot:view>
                                                <template v-for="executer in executers">
                                                    <v-enterprise-chip
                                                        v-if="executer.ExecuterID == guidEmpty"
                                                        :key="executer.id"
                                                        :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.id"
                                                        :id="executer.ExecuterID"
                                                        :name="executer.ExecuterName"
                                                    />
                                                </template>
                                            </template>

                                        </v-field>

                                    </template>
                                    <template v-else>                                        
                                        <v-field 
                                            v-if="isControlTypeControl"
                                            label="Ответственный"
                                            :wide="true"
                                        >
                                            <template v-slot:edit>
                                                <draggable
                                                    v-model="svodExecuters"
                                                    :class="['more-per-lab-wrap', 'onlyReadData', dragInProcess ? 'isDragClass' : '']"
                                                    :group="{ name: 'executers', pull: false }"
                                                    draggable=".item-drag:not(.disable-drag-wo)"
                                                >
                                                    <div class="chipAreaNoData d-flex align-center pl-1"
                                                        v-if="svodExecuters.length == 0"
                                                    >
                                                        <v-select-members-chip
                                                            class="disable-drag-wo"
                                                            target-type="Member"
                                                            @on-select="onSelectMainExecuterFromChip"
                                                            :items-params="executersSelectParams"
                                                            :required="true"
                                                        />
                                                    </div>
                                                    <template v-else v-for="executer in svodExecuters">
                                                        <v-enterprise-chip
                                                            v-if="executer.ExecuterID == guidEmpty"
                                                            :key="executer.id"
                                                            :id="executer.EnterpriseID"
                                                            :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                            :main="true"
                                                            :class="['item-drag', 'disable-drag-wo']"
                                                            :canDelete="!executersSelectParams.implicitExclude.includes(item.EnterpriseID)"
                                                            :delFunc="onRemoveSelectedByEnterpriseId"
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :key="executer.id"
                                                            :id="executer.ExecuterID"
                                                            :name="executer.ExecuterName"
                                                            :main="true"
                                                            :class="['item-drag', 'disable-drag-wo']"
                                                            :canDelete="!executersSelectParams.implicitExclude.includes(item.ExecuterID)"
                                                            :delFunc="onRemoveSelectedByExecuterId"
                                                        />
                                                    </template>
                                                    
                                                </draggable>
                                            </template>
                                        </v-field>  

                                        <v-field
                                            :label="(isControlTypeControl ? 'Соисполнители' : 'Исполнители' )"
                                            :wide="true"
                                        >
                                            <template v-slot:edit>
                                                <draggable
                                                    v-model="executers"
                                                    :class="['more-per-lab-wrap', 'onlyReadData']"
                                                    :sort="false"
                                                    :group="{ name: 'executers' }"
                                                    @start="onDragStart"
                                                    @end="onDragEnd"
                                                    draggable=".item-drag:not(.disable-drag-wo)"
                                                >
                                                    <div class="chipAreaNoData d-flex align-center pl-1" v-if="executers.length == 0 && !isControlTypeControl">
                                                        <v-select-members-chip
                                                            class="disable-drag-wo"
                                                            target-type="Member"
                                                            @on-select="onSelectExecuterFromChip"
                                                            :items-params="executersSelectParams"
                                                            :required="!isControlTypeControl"
                                                        />
                                                    </div>
                                                    <template v-else v-for="executer in executers">
                                                        <v-enterprise-chip
                                                            v-if="executer.ExecuterID == guidEmpty"
                                                            :key="executer.id"
                                                            :id="executer.EnterpriseID"
                                                            :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                            :class="['item-drag']"
                                                            :canDelete="!executersSelectParams.implicitExclude.includes(item.EnterpriseID)"
                                                            :delFunc="onRemoveSelectedByEnterpriseId"
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :key="executer.id"
                                                            :id="executer.ExecuterID"
                                                            :name="executer.ExecuterName"
                                                            :class="['item-drag']"
                                                            :canDelete="!executersSelectParams.implicitExclude.includes(item.ExecuterID)"
                                                            :delFunc="onRemoveSelectedByExecuterId"
                                                        />
                                                    </template>

                                                    <v-select-members-chip
                                                        v-if="isControlTypeControl || executers.length > 0"
                                                        class="disable-drag-wo"
                                                        target-type="Member"
                                                        @on-select="onSelectExecuterFromChip"
                                                        :items-params="executersSelectParams"
                                                    />

                                                </draggable>                                            
                                            </template>
                                        </v-field>
                                    </template>

                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->
                            
                            <!--#region Ход исполнения-->
                            <v-col v-if="isViewMode" cols="12" sm="12" md="12" class="section-wrapper">
                                <v-card-panel title="Ход_исполнения">

                                    <template v-slot:titleactions>              
                                        <div class="fbt-slider">
                                            <div class="prev-title">{{$t('Показать_все_исполнения')}}</div>
                                            <v-switch 
                                                v-model="allExecutions" 
                                                class="cust-switch" 
                                                inset 
                                                hide-details                                               
                                            >
                                            </v-switch>
                                            <div
                                                class="arrow-dropdown"
                                                @click="toggleExpanded"
                                            >
                                                <v-icon 
                                                role="button" 
                                                small 
                                                left>
                                                    {{ expanded ? "fas fa-chevron-up" : "fas fa-chevron-down" }}
                                                </v-icon>
                                            </div>
                                        </div>
                                    </template>
                    
                                    <v-execution-tree
                                        :task-click="taskClickHandler"
                                        :task-dbl-click="taskDblClickHandler"
                                        :allExecutions="allExecutions"
                                        :expanded.sync="expanded"
                                        :is-edit-mode="isEditMode"
                                    >
                                    </v-execution-tree>
                                </v-card-panel>
                            </v-col>
                            <!--#endregion-->
                            
                        </v-row>
                    </v-form>
                </template>
                
                <template v-else>
                    <component                         
                        :is="$mapComponent(item.FormId)"
                        :ref="item.FormId"
                        :id="resolution.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

        <SelectResolutionTextDlg v-if="!isViewMode" ref="SelectResolutionTextDlgRef" />

    </div>
</template>

<script>
import sys from '@/services/system';
import draggable from 'vuedraggable';
import _ from 'lodash';
import i18n from '@/i18n';
import { mapActions, mapGetters } from 'vuex';

import Toolbar from '@/components/documents/Toolbar.vue';
import DocumentPanel from '@/components/documents/DocumentPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import SelectResolutionTextDlg from '@/components/global/dialogs/SelectResolutionTextDlg';

export default {
    name: "Resolution",
    rssStatusKeys:["", "rssOnSign", "rssSignedByChief", "rssSended"],
    components: {
        Toolbar,
        DocumentPanel,
        SelectResolutionTextDlg,
        StatusChip,
        draggable
    },     
    data() {
        return {
            tab: null,
            openAll: true,
            allExecutions: false,
            expanded: false,
            controleDateMenu: false,

            dragInProcess: false,

            //#region for autocomplete
            listResolutionsText: [],
            id: 'input-' + window.crypto.getRandomValues(new Uint16Array(1))[0],
			searchMatch: [],
			selectedIndex: 0,
			clickedChooseItem: false,
			wordIndex: 0
            //#endregion
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('documents/references', ['GetReference', 'GetAvaibleStatuses']),
        ...mapGetters('global/actionsource', { dataSource: 'getDataSource', dataSourceType: 'getDataSourceType', isDataSourceCommon: 'isDataSourceCommon'}),
        ...mapGetters('global/auth', { userInfo: 'getUserInfo', isCommonServiceEnabled: 'isCommonServiceEnabled' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule'
        }),
        innerLimitRule() {
            if (!this.isControlTypeControl || this.innerLimit)
                return true;
            else 
                return "Поле_обязательно";
        },
        allowedControlTypes() {
            let list = this.GetReference(1004).filter(i => 
                this.dataSource?.Data?.AdditionalObjects 
                && this.dataSource?.Data?.AdditionalObjects.length 
                && this.dataSource?.Data?.AdditionalObjects[0]
                && this.dataSource?.Data?.AdditionalObjects[0].length
                    ? this.dataSource.Data.AdditionalObjects[0].includes(i.id)
                    : true
            );

            if (this.document?.Card?.Periodicity && list.filter(item=>item.id==1).length > 0) {
                list.push({ id: 101, Value: this.$t("Периодический_контроль") });
            }

            return list;
        },
        isMain() {
            return this.dataSource?.Data?.AdditionalObjects?.[4] ?? false;
        },
        isDataSourceCorrect() {                
            return this.dataSourceType === 'Resolution';
        },
        controlDateRange() {
            let max = this.$moment(this.task.MaxControlDate)
            let min = this.$moment(this.dataSource?.Data?.Object?.Resolution?.ResolutionDate);
            let Max = "";
            let Min = "";

            if (max && max.isValid()) {
                Max = max.format('YYYY-MM-DD');

                if (min && min.isValid() && min.isBefore(max))                           
                    Min = min.format('YYYY-MM-DD'); 
                else   
                    Min = Max;
            }
            else if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }
            return { Max, Min };
        },
        innerLimit: {
            get: function() {
                if (this.task.InnerLimit)
                    return this.$moment(this.task.InnerLimit).format('YYYY-MM-DD');
                
                return this.task.InnerLimit;
            },
            set: function(v) {
                this.updateSource({ property: `Data.Object.Resolution.Tasks[0].InnerLimit`, value: `/Date(${Date.parse(v)})/` });
            }
        },
        guidEmpty() {
            return '00000000-0000-0000-0000-000000000000';
        },
        isViewMode() {
            return this.dataSource?.Data?.FormId == "0205021" || this.isExecutersEditMode;
        },
        isEditMode() {
            return this.dataSource?.Data?.FormId == "0205022";
        },
        isExecutersEditMode() {
            return this.dataSource?.Data?.FormId == "0205022" && this.resolutionHasExecutions;
        },
        resolution() {
            return this.dataSource?.Data?.Object?.Resolution;
        },
        task() {
            return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0]
        },
        document() {
            return this.dataSource?.Data?.Object?.Resolution?.Document;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.resolution?.id, Name: this.$t("Карточка") }
            ];

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);
            
            return pages;
        },
        isControlTypeControl() {
            return ![3,4].includes(this.task?.ControlType?.id);
        },
        isEuolResolution() {
            return this.document?.__type === "EuolStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects";
        },
        isORDResolution() {
            return this.document?.__type === "InnerDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects" && this.document?.Card?.TypeC4 === 1;  
        },
        isActItemResolution() {
            return (this.document?.__type === "IncomingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects" || this.document?.__type === "ProtocolDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects")
                && this.document?.Card?.TypeC4 === 3;
        },
        isProtocolResolution() {
            return this.resolution.IsProtocol;
        },
        svodExecuters: {
            get: function () {
                return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers?.filter(e => e.Svod);
            },
            set: function(value) {
                if (!value)
                    return;

                let oldMainExecuter = value.find(executer => executer.Svod === true); // реактивный объект, ссылка на объект из Data.Object.Resolution.Tasks[0].Executers
                let indexOfNewMainMainExecuter = 1 - value.indexOf(oldMainExecuter);
                let newMainMainExecuter = value[indexOfNewMainMainExecuter]; // реактивный объект, ссылка на объект из Data.Object.Resolution.Tasks[0].Executers
                let allExecuters = Array.from(this.task.Executers); // реактивный объект, ссылка на массив Data.Object.Resolution.Tasks[0].Executers
                let oldMEIndex = allExecuters.indexOf(oldMainExecuter);
                let newMEIndex = allExecuters.indexOf(newMainMainExecuter);
                let tmp = JSON.parse(JSON.stringify(oldMainExecuter)); // deep copy, не реактивно
                allExecuters[oldMEIndex].Svod = false;
                allExecuters[oldMEIndex].IndexInTask = newMainMainExecuter.IndexInTask;
                allExecuters[newMEIndex].Svod = true;
                allExecuters[newMEIndex].IndexInTask = tmp.IndexInTask;
                // обновляем через мутацию, для обработки признака IsEdited в таске
                this.updateSource({ property: `Data.Object.Resolution.Tasks[0].Executers`, value: allExecuters });
            }
        },
        executers: {
            get: function () {
                return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers
                    .filter(e => !e.Svod)
                    .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1);
            },
            set: function () {
                return;
            }
        },            
        selectResolutionTextTooltip() {
            return this.$t("Выбрать_текст_резолюции");
        },
        selectExecuterTooltip() {
            return this.$t("Выбрать_исполнителей");
        },
        isPeriodicControl() {
            return this.task?.ControlType?.id === 101;
        },
        //#region for autocomplete
        listToSearch() {
			return this.listResolutionsText;
		},
		currentWord() {
			return this.task.ResolutionText?.replace(/(\r\n|\n|\r)/gmi, ' ').split(' ')[this.wordIndex];
		},
		inputSplitted() {
			return this.task.ResolutionText?.replace(/(\r\n|\n|\r)/gmi, ' ').split(' ');
		},
        taskResolutionText(){
            return this.task?.ResolutionText;
        },
        //#endregion
        hasOuterExecuters() {
            return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers.some(x => x.IsInner === false); //some это any
        },
        hasOuterEnterprise() {
            return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers.some(x => x.ExecuterID === this.guidEmpty); //some это any
        },
        resolutionHasExecutions() {
            return this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers.some(x => ![0, 4, 7].includes(x.WorkStatus)); //some это any
        },
        //#region Common схема
        //Возможность выбора способа отправки рез-ции (общая схема / отправка исходящего)
        canEditResolutionSendTypeField() {
            // у сервера, с которым работает пользователь нет возможность работы с общими док-тами
            if (!this.isCommonServiceEnabled)
                return false;

            // родительский документ НЕ входящий или ДИРОРД
            if (typeof this.dataSource?.Data?.Object?.Resolution?.Document?.Card?.TypeC4 == 'undefined' || ![0, 2].includes(this.dataSource.Data.Object.Resolution.Document.Card.TypeC4))
                return false;

            // входящий документ не контрольный
            if (typeof this.dataSource?.Data?.Object?.Resolution?.Document?.Card?.ControlType == 'undefined' || ![1, 2].includes(this.dataSource.Data.Object.Resolution.Document.Card.ControlType))
                // если это не ДирОРД
                if (this.dataSource?.Data?.Object?.Resolution?.Document?.Card.TypeC4 !== 2)
                    return false;

            // родительский документ уже общий
            if (this.isParentDocumentCommon)
                return false;

            // родительский документ в рез-циях содержит внешних исполнителей
            if (this.isParentDocumentHasOuterExecuter)
                return false;

            // в списке выбранных исполнителей нет внешних исполнителей
            if (!this.hasOuterExecuters)
                return false;

            // в списке выбранных исполнителей есть внешние корреспондентов
            if (this.hasOuterEnterprise)
                return false;

            // резолюция, которую создали с помощью права "ChancelleryAllDocumentsCreateResolution" или связанный ДирОРД не может быть перенесен в общую схему
            if (this.isChancelleryResolutionCreation)
                return false;

            return true;
        },
        resolutionSendTypes() {
            return [
                { id: true, Value: this.$t("Общая_схема") },
                { id: false, Value: this.$t("Отправка_исходящего") }
            ];
        },
        isParentDocumentCommon() {
            return  this.dataSource?.Data?.Object?.Resolution?.Document?.IsCommon;
        },
        isParentDocumentHasOuterExecuter() {
            return  this.dataSource?.Data?.AdditionalObjects && // есть доп. массив со сведениями
                    this.dataSource?.Data?.AdditionalObjects.length && // он не пустой
                    this.dataSource?.Data?.AdditionalObjects[1] !== undefined && // и 2-ой элемент массива существует
                    this.dataSource?.Data?.AdditionalObjects[1] === true; // и значение 2-ого элемента имеет значение "false" типа boolean (на документе еще нет внешних исполнителей)
        },
        isParentDirordCanBeCommon() {
            return  this.dataSource?.Data?.AdditionalObjects && // есть доп. массив со сведениями
                    this.dataSource?.Data?.AdditionalObjects.length && // он не пустой
                    this.dataSource?.Data?.AdditionalObjects[3] !== undefined && // и 4-ий элемент массива существует
                    this.dataSource?.Data?.AdditionalObjects[3] === true; // ДирОРД может быть перенесен в общую схему
        },
        isChancelleryResolutionCreation() {
            return  this.dataSource?.Data?.AdditionalObjects && // есть доп. массив со сведениями
                    this.dataSource?.Data?.AdditionalObjects.length && // он не пустой
                    this.dataSource?.Data?.AdditionalObjects[2] !== undefined && // и 3-ий элемент массива существует
                    this.dataSource?.Data?.AdditionalObjects[2] === true; // и значение 3-ого элемента имеет значение "true" типа boolean (резолюция создана с помощью права "ChancelleryAllDocumentsCreateResolution")
        },
        //#endregion
        executersSelectParams() {
            let allowOuterExecutersInPeriodic = !this.isPeriodicControl || this.isParentDocumentCommon || (this.isCommonServiceEnabled && !this.isParentDocumentHasOuterExecuter);
            let executersToExclude = Array.from(this.task.Executers)
                    .filter(x => x.WorkStatus == 16 || x.HasExecutions === true || (x.Svod == true && this.resolutionHasExecutions))
                    .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID);

            let implicitExclude = [ this.dataSource.Data.Object.Resolution.SignerWorkPlaceId ].concat(executersToExclude);

            return {
                includeInner: true,
                includeOuter: allowOuterExecutersInPeriodic && !this.isORDResolution && (!this.isActItemResolution || (this.isCommonServiceEnabled && this.isParentDirordCanBeCommon)), // если это рез-ция не на ОРД и либо есть общая схема либо это рез-ция не на пункт
                includeOuterEnterprises: !this.isORDResolution && !this.isActItemResolution && this.userInfo.Permissions.includes("CanSignChief") && !this.isDataSourceCommon && !this.isPeriodicControl,
                multiple: true,
                selected: Array.from(this.task.Executers)
                    .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1)
                    .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID),
                implicitExclude,
                hierarchical: true
            };
        }
    },
    methods: {
        ...mapActions('documents/references', ['getReference']),
        ...mapActions('global/actionsource', { updateSource: 'updateDataSource' }),
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
        onTabClicked (formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('global/actionsource/')).map(i => { return i.split('/')[2] })
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if (validate_result && ((this.svodExecuters.length > 0 && this.isControlTypeControl) || (this.executers.length > 0 && !this.isControlTypeControl))) {
                        this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].IsPeriodic', value: this.task.ControlType.id == 101 });
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});
                    } else {
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Action ${button.Action} not emplemented`);
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        taskClickHandler() {

        },
        taskDblClickHandler(item) {
            if (item.text === '2')
                this.$store.dispatch(`global/actionsource/ExecuterShowExecution`, { executionData : { resolutionId: item.ViewInfo.ResolutionId, executionId: item.id, common: this.isDataSourceCommon } });
                //чистим выделение текста на странице, если оно случается вдруг
                if (window.getSelection) { window.getSelection().removeAllRanges(); }
                else if (document.selection && document.selection.clear)
                    document.selection.clear();
        },
        async onSelectResolutionText() {
            try
            {
                let resolutionText = await this.$refs.SelectResolutionTextDlgRef.open({ title: 'Справочник_текста_резолюций' });
                this.clickedChooseItem = false;
                this.searchMatch = [];
                this.updateSource({ 
                    property: 'Data.Object.Resolution.Tasks[0].ResolutionText', 
                    value: resolutionText + ' ' 
                });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async onExecuterSelect() {
            try
            {
                let allowOuterExecutersInPeriodic = !this.isPeriodicControl || this.isParentDocumentCommon || (this.isCommonServiceEnabled && !this.isParentDocumentHasOuterExecuter);

                let executersToExclude = Array.from(this.task.Executers)
                    .filter(x => x.WorkStatus == 16 || x.HasExecutions === true || (x.Svod == true && this.resolutionHasExecutions))
                    .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID);

                let implicitExclude = [ this.dataSource.Data.Object.Resolution.SignerWorkPlaceId ].concat(executersToExclude);                

                let selectMemberParams =  {
                    title: this.$t("Выбор_исполнителей"),
                    includeInner: true,
                    includeOuter: allowOuterExecutersInPeriodic && !this.isORDResolution && (!this.isActItemResolution || (this.isCommonServiceEnabled && this.isParentDirordCanBeCommon)), // если это рез-ция не на ОРД и либо есть общая схема либо это рез-ция не на пункт
                    includeOuterEnterprises: !this.isORDResolution && !this.isActItemResolution && this.userInfo.Permissions.includes("CanSignChief") && !this.isDataSourceCommon && !this.isPeriodicControl,
                    includeExecutersGroups: true,
                    multiple: true,
                    selected: Array.from(this.task.Executers)
                        .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1)
                        .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID),
                    implicitExclude,
                    hierarchical: true
                }

                let result = await this.$store.dispatch('global/dialogs/selectMembersNew/open', selectMemberParams);

                let currResolutionTaskExecuters = Array.from(this.task.Executers ?? []).reduce((acc, val) => {
                    if (result.find( x => x.type == 1 ? x.workplaceId == val.ExecuterID : x.enterprise == val.EnterpriseID))
                        acc.push(val);

                    return acc;
                }, []);

                let resolutionTaskExecuters = result.reduce((executers, selectedExecuter, index) => {
                    let existed = currResolutionTaskExecuters.find(x => selectedExecuter.type == 1 ? x.ExecuterID == selectedExecuter.workplaceId: x.EnterpriseID == selectedExecuter.enterprise);
                    
                    if (!existed)
                        executers.push(sys.prepareResolutionTaskExecuter(this.task.id, index, selectedExecuter));
                    else
                        existed.IndexInTask = index;

                    return executers;
                }, currResolutionTaskExecuters);

                if (this.isControlTypeControl && !resolutionTaskExecuters.some(x => x.Svod === true))
                    resolutionTaskExecuters[0].Svod = true;

                this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value: resolutionTaskExecuters });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        onDragStart() {
            this.dragInProcess = true;
        },
        onDragEnd() {
            this.dragInProcess = false;
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
        onSelectMainExecuterFromChip(e) {
            let te = sys.prepareResolutionTaskExecuter(this.task.id, 0, e.value);
            te.Svod = true;
            let value = [ ...this.task.Executers, te];
            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        onSelectExecuterFromChip(e) {
            let svodIndex = this.task.Executers.indexOf(this.task.Executers.find(x => x.Svod));
            let index = svodIndex < 0 ? this.task.Executers.length + 1 : this.task.Executers.length;
            let te = sys.prepareResolutionTaskExecuter(this.task.id, index, e.value);
            let value = [ ...this.task.Executers, te];
            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        onRemoveSelectedByEnterpriseId(id) {
            let executer = this.task.Executers.find(x => x.EnterpriseID === id && x.ExecuterID === sys.guidEmpty());
            let value = this.task.Executers.filter(item => item !== executer);

            if (executer.IndexInTask > 0) {
                value.forEach((x) => {
                    if (x.IndexInTask > executer.IndexInTask)
                        x.IndexInTask -= 1;
                });
            }

            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        onRemoveSelectedByExecuterId(id) {
            let executer = this.task.Executers.find(x => x.ExecuterID === id);
            let value = this.task.Executers.filter(item => item !== executer);

            if (executer.IndexInTask > 0) {
                value.forEach((x) => {
                    if (x.IndexInTask > executer.IndexInTask)
                        x.IndexInTask -= 1;
                });
            }

            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value });
        },
        //#region for autocomplete 
        highlightWord(word) {
			return String(word).replace(new RegExp(this.currentWord, 'gi'), match => {
                return `<span style="background:#C5E1A5">${match}</span>`;
            });
		},
		setWord(word) {
            let currentWords = this.task.ResolutionText?.replace(/(\r\n|\n|\r)/gm, '__br__ ').split(' ');
            
            if(!currentWords[this.wordIndex]) return;

			currentWords[this.wordIndex] = currentWords[this.wordIndex].replace(this.currentWord, word + ' ');
            //this.wordIndex += 1;
			this.update({ property: 'Data.Object.Resolution.Tasks[0].ResolutionText', value: currentWords.join(' ').replace(/__br__\s/g, '\n') });
        },
		moveDown(e) {
            e.preventDefault();
			if (this.selectedIndex < this.searchMatch.length - 1) {
				this.selectedIndex++;
                this.scrollListMenu(this.selectedIndex * 40);
			}
		},
		moveUp(e) {
            e.preventDefault();
			if (this.selectedIndex > 0) {
				this.selectedIndex--;
                this.scrollListMenu(this.selectedIndex * 40);
            }
		},
        scrollListMenu(offset){
            const root = document.querySelector("." + this.id + "-list");
            if(root){
                root.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });
            }  
        },
		selectItem(index) {
			this.selectedIndex = index;
			this.chooseItem();
		},
		chooseItem(e) {
			
			if (this.searchMatch.length > 0) {
				if (e) {
					e.preventDefault();
				}
				this.setWord(this.searchMatch[this.selectedIndex]);
				this.selectedIndex = -1;
                this.scrollListMenu(0);
			}
		},
		focus() {
			this.searchMatch = [];
			if (this.currentWord !== "" && this.currentWord?.length > 2) {
				this.searchMatch = this.listToSearch?.filter(x => sys.checkSearch(x,this.currentWord)) ?? [];
			}
			if (
				this.searchMatch.length === 1 &&
				this.currentWord === this.searchMatch[0]
			) {
				this.searchMatch = [];
			}
		},
        clickTA(){
            this.clickedChooseItem = true;
        },
        //caret position
        getCaretPosPX(objName) {
            let elementTA = document.querySelector('#' + objName);
            let element = document.querySelectorAll('.' + objName + '-list');
            let coordinates = this.getCaretCoordinates(elementTA, elementTA.selectionEnd);
            let topCoordinates = coordinates.top - elementTA.scrollTop;
            
            if (element[0]) {
                element[0].style.top = topCoordinates + 20 + 'px';
                element[0].style.left = coordinates.left + 20 + 'px';
            }
            //делаем возможным получение индекса слова 
            this.cropStrToCaret(elementTA);
            
        },
        cropStrToCaret(elementTA){
            setTimeout(() => {
                let strLengthToCaret = elementTA.selectionStart;
                let strTextToCaret = this.task.ResolutionText?.slice(0, strLengthToCaret) ?? '';
                let arrTextToCaret = strTextToCaret.replace(/(\r\n|\n|\r)/gmi, ' ').split(' ');
                this.wordIndex = arrTextToCaret.length - 1;
                this.focus();
                /*console.log(arrTextToCaret);
                console.log(arrTextToCaret.length - 1);
                console.log(this.currentWord);*/
            }, 100);

        },
        getCaretCoordinates(element, position) {
            // The properties that we copy into a mirrored div.
            // Note that some browsers, such as Firefox,
            // do not concatenate properties, i.e. padding-top, bottom etc. -> padding,
            // so we have to do every single property specifically.
            var properties = [
            'boxSizing',
            'width',  // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
            'height',
            'overflowX',
            'overflowY',  // copy the scrollbar for IE

            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',

            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',

            // https://developer.mozilla.org/en-US/docs/Web/CSS/font
            'fontStyle',
            'fontVariant',
            'fontWeight',
            'fontStretch',
            'fontSize',
            'lineHeight',
            'fontFamily',

            'textAlign',
            'textTransform',
            'textIndent',
            'textDecoration',  // might not make a difference, but better be safe

            'letterSpacing',
            'wordSpacing'
            ];

            var isFirefox = !(window.mozInnerScreenX == null);
            //var mirrorDivDisplayCheckbox = document.getElementById('mirrorDivDisplay');
            var mirrorDiv, computed, style;
            // mirrored div
            mirrorDiv = document.getElementById(element.nodeName + '--mirror-div');
            if (!mirrorDiv) {
                mirrorDiv = document.createElement('div');
                mirrorDiv.id = element.nodeName + '--mirror-div';
                document.body.appendChild(mirrorDiv);
            }

            style = mirrorDiv.style;
            computed = getComputedStyle(element);

            // default textarea styles
            style.whiteSpace = 'pre-wrap';
            if (element.nodeName !== 'INPUT')
                style.wordWrap = 'break-word';  // only for textarea-s

            // position off-screen
            style.position = 'absolute';  // required to return coordinates properly
            style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
            style.left = "400px";
            //style.visibility = mirrorDivDisplayCheckbox.checked ? 'visible' : 'hidden';  // not 'display: none' because we want rendering

            // transfer the element's properties to the div
            properties.forEach(function (prop) {
                style[prop] = computed[prop];
            });

            if (isFirefox) {
                style.width = parseInt(computed.width) - 2 + 'px'  // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
                // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
                if (element.scrollHeight > parseInt(computed.height))
                style.overflowY = 'scroll';
            } else {
                style.overflow = 'hidden';  // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
            }  

            mirrorDiv.textContent = element.value.substring(0, position);
            // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
            if (element.nodeName === 'INPUT')
                mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, "\u00a0");

            var span = document.createElement('span');
            // Wrapping must be replicated *exactly*, including when a long word gets
            // onto the next line, with whitespace at the end of the line before (#7).
            // The  *only* reliable way to do that is to copy the *entire* rest of the
            // textarea's content into the <span> created at the caret position.
            // for inputs, just '.' would be enough, but why bother?
            span.textContent = element.value.substring(position) || '.';  // || because a completely empty faux span doesn't render at all
            span.style.backgroundColor = "lightgrey";
            mirrorDiv.appendChild(span);

            var coordinates = {
                top: span.offsetTop + parseInt(computed['borderTopWidth']),
                left: span.offsetLeft + parseInt(computed['borderLeftWidth'])
            };
            mirrorDiv.remove();
            return coordinates;
        }        
        //#endregion
    },
    watch: {
        isControlTypeControl(val) {

            if (this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers && this.dataSource?.Data?.Object?.Resolution?.Tasks?.[0].Executers.length) {

                if (val) {                    
                    this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers[0].Svod', value: true });
                }
                else {
                    for (var index = 0; index < this.dataSource.Data.Object.Resolution.Tasks[0].Executers.length; index++)
                        this.updateSource({ property: `Data.Object.Resolution.Tasks[0].Executers[${index}].Svod`, value: false });
                }

            }
        },
        canEditResolutionSendTypeField(val) {
            if (!this.isEditMode)
                return;
            
            this.updateSource({ property: 'Data.Object.Resolution.IsCommon', value: val });
        },
        hasOuterExecuters(val) {
            if (!this.isEditMode)
                return;

            let canSwitchToCommon = this.dataSource?.Data?.Object?.Resolution?.IsCommon;

            if (this.isChancelleryResolutionCreation) {
                canSwitchToCommon = this.isParentDocumentCommon ||
                                    (
                                        this.isCommonServiceEnabled && // и у сервера, с которым работает пользователь есть возможность работы с общими док-тами
                                        typeof this.dataSource?.Data?.Object?.Resolution?.Document?.Card?.TypeC4 != 'undefined' &&
                                        [0, 2].includes(this.dataSource.Data.Object.Resolution.Document.Card.TypeC4) && //если это входящий или ДИРОРД
                                        typeof this.dataSource?.Data?.Object?.Resolution?.Document?.Card?.ControlType != 'undefined' &&
                                        ([1, 2].includes(this.dataSource.Data.Object.Resolution.Document.Card.ControlType) || this.dataSource?.Data?.Object?.Resolution?.Document?.Card.TypeC4 === 2) && // и входящий документ контрольный или ДИРОРД
                                        !this.isParentDocumentHasOuterExecuter && //родительский документ может быть перенесен в общую схему
                                        val === true && // и в списке выбранных исполнителей есть внешние исполнители
                                        this.hasOuterEnterprise === false // и в списке выбранных исполнителей нет внешних корреспондентов
                                    );
            }

            if (this.isActItemResolution) {
                canSwitchToCommon = this.isParentDocumentCommon ||
                                    (
                                        this.isCommonServiceEnabled && // и у сервера, с которым работает пользователь есть возможность работы с общими док-тами
                                        typeof this.dataSource?.Data?.Object?.Resolution?.Document?.Card?.ControlType != 'undefined' &&
                                        [1, 2].includes(this.dataSource.Data.Object.Resolution.Document.Card.ControlType) && // если документ контрольный
                                        !this.isParentDocumentHasOuterExecuter && //родительский документ может быть перенесен в общую схему
                                        val === true && // и в списке выбранных исполнителей есть внешние исполнители
                                        this.hasOuterEnterprise === false && // и в списке выбранных исполнителей нет внешних корреспондентов
                                        this.isParentDirordCanBeCommon // и родительский ДирОРД может быть перенесен в общую схему
                                    );
            }

            this.updateSource({ property: 'Data.Object.Resolution.IsCommon', value: canSwitchToCommon });
        },
        isPeriodicControl(val) {
            // если это не режим редактирования
            if (!this.isEditMode)
                return;
            // если это не периодический контроль
            if (!val)
                return;
            // если родительский документ уже общий или сервис доступен и родительский документ не содержит внешних исполнителей/корреспондентов
            if (this.isParentDocumentCommon || (this.isCommonServiceEnabled && !this.isParentDocumentHasOuterExecuter))
                return;

            let removedCount = 0, index = 0;
            let isMainRemoved = false;
            let resolutionTaskExecuters = Array.from(this.task.Executers ?? []);

            while (index < resolutionTaskExecuters.length) {
                resolutionTaskExecuters[index].IndexInTask = resolutionTaskExecuters[index].IndexInTask - removedCount;

                if (!resolutionTaskExecuters[index].IsInner) {

                    if (resolutionTaskExecuters[index].Svod)
                        isMainRemoved = true;

                    resolutionTaskExecuters.splice(index, 1);
                    removedCount++;
                }
                else
                    index++;
            }

            if (isMainRemoved)
                resolutionTaskExecuters[0].Svod = true;
            
            this.updateSource({ property: 'Data.Object.Resolution.Tasks[0].Executers', value: resolutionTaskExecuters });
        },
        //#region for autocomplete
        taskResolutionText: function (val) {
            if (!val)
                return;

            this.selectedIndex = 0;

            if (this.clickedChooseItem)
                this.getCaretPosPX(this.id);
        }
        //#endregion
    },
    async created() {        
        (async() => {
            this.listResolutionsText = (await this.getReference({ id: 1017 })).map(item => item.Value);
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
        document.addEventListener('click', (e) => {
            if(e?.target?.value + e?.target?.localName != this?.task?.ResolutionText + 'textarea'){
                this.clickedChooseItem = false;
            }
        });
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    }
}
</script>